<template>
	<div class="mt-3">
		<b-form v-if="machine_bypass || ( isReady && printers.length )" @submit.prevent="printLabel">

            <div class="row mb-3">
                <div class="col-sm-5 m-auto">
					<div class="small">
						<span v-if="label_printer" class="text-black-50">Label <b-icon-printer></b-icon-printer> :</span> {{label_printer}} 
						<span class="float-right" title="Edit Printer Settings" v-b-tooltip.hover>
							<b-icon-pencil-square class="m-1 pointer text-success" @click="isReady=false"></b-icon-pencil-square>
						</span>
					</div>
					<div class="input-group mb-3">
						<b-form-input type="text" tabindex="1" :disabled="errormsg ? true : false" autocomplete="off" ref="lm_or_hawm" @keydown.tab="printLabel" autofocus v-model="lm_awb" placeholder="Scan or Enter AWB"></b-form-input>
					</div>
					<div v-if="errormsg" class="alert alert-danger"> {{ errormsg }} </div>

					<div v-if="isLoaded" class="text-center">
						<b-spinner label="Spinning"></b-spinner>
					</div>

					<div v-if="label_url">

						<!-- Print Label -->
						<div id="capture-label">
							<div v-if="labelfiletype == 'pdf'">
								<a :href="label_url" :download="filename">Download Label</a> 
							</div>
							<img v-else :src="label_url" alt="label">
						</div>

					</div>

                </div>
            </div>

		</b-form>

		<b-form v-if="machine_bypass || ( isReady && printers.length )" @submit.prevent="getSellerDoc">
				<div class="row mb-3">
					<div class="col-sm-5 m-auto">
						<div class="small">
							<span class="text-black-50">Seller Doc PDF <b-icon-printer></b-icon-printer> : </span> {{ seller_doc_printer || "No Printer Selected"}} 
							<span class="float-right" title="Edit Printer Settings" v-b-tooltip.hover>
								<b-icon-pencil-square class="m-1 pointer text-success" @click="isReady=false"></b-icon-pencil-square>
							</span>
						</div>
						<div class="input-group mb-3">
							<b-form-input type="text" tabindex="1" :disabled="errormsg ? true : false" autocomplete="off" ref="sellerDocAwb" @keydown.tab="printLabel" autofocus v-model="sellerDocAwb" placeholder="Scan or Enter AWB"></b-form-input>
						</div>

						<div v-if="ifSellerDocLoading" class="text-center">
							<b-spinner label="Spinning" style="color:#ffc107" ></b-spinner>
						</div>


						<div v-if="sellerDocUrl">
							<!-- Print Seller Doc -->
							<div id="capture-seller-doc">
								<div v-if="filetype == 'pdf'">
									<a :href="sellerDocUrl" :download="filename">Download Label</a> 
								</div>
								<img v-else :src="sellerDocUrl" alt="sellerDoc"  id="sellerDocImage" @error="imageLoadError">
							</div>
						</div>
					</div>
				</div>
		</b-form>
		<div v-else>
			<AssignPrinter :printers="printers" v-if="printers" />
		</div>

	</div>
</template>

<script>

import html2canvas from 'html2canvas'
import * as JSPM from 'jsprintmanager'
import AssignPrinter from '@/components/AssignPrinter.vue'

export default {
	name: "PreScan",
	title: "Pre Scan",
	data() {
		return {
			updated: false,
			lm_awb: '',
			label_url: '',
			dpdLabel_url: '',
			errormsg: '',
			printers: [],
			isReady: false,
			isLoaded: false,
			ifSellerDocLoading: false,
			sellerDocAwb:'',
			sellerDocUrl: '',
			filetype: 'png',
			labelfiletype: 'png',
			filename: '',
			machine_bypass: process.env.VUE_APP_MACHINE_USE == 'true' ? true : false
		}
	},
	components: {
		AssignPrinter
	},
	mounted() {
		this.onInit();
		if( this.label_printer || this.seller_doc_printer) {
			this.isReady = true
		}
	},
	methods: {

		onInit: function () {
			var _this = this;
			JSPM.JSPrintManager.license_url = `${process.env.VUE_APP_API_ENDPOINT}/wh-license`;
			JSPM.JSPrintManager.auto_reconnect = true;
			JSPM.JSPrintManager.start();
			JSPM.JSPrintManager.WS.onStatusChanged = function () {
				_this.getPrinters().then((p) => {
					_this.printers = p;
					_this.$nextTick();
				});
			};
		},
		getPrinters: function () {
			return new Promise((ok, err) => {
				let printers = [null];
				if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
					this.errormsg = ''
					JSPM.JSPrintManager.getPrinters()
						.then(function (myPrinters) {
							printers.push(...myPrinters);
							ok(printers);
						})
						.catch((e) => err(e));
				} else {
					console.warn("JSPM WS not open");
					this.errormsg = 'Please start JSPrintManager application.'
					ok(printers);
				}
			});
		},

		async printLabel() {
			try {
				this.isLoaded = true
				const res = await this.axios.post(`/pre-scan`, {awb: this.lm_awb})
				if(res.data.success) {
					this.label_url = res.data?.labelPath;
					this.dpdLabel_url = res.data?.dpdLabelPath;

					// Patch for direct print of PDF for Express, CAN and SGP

					// Print DPD label
					if(this.dpdLabel_url) {
						this.labelfiletype = 'dpdLabel'
					}
					else if(this.label_url.includes('application/pdf') || this.label_url.includes('application/octet-stream')) {
						this.labelfiletype = 'pdf'
					}
					else {
						this.labelfiletype = 'png'
					}

					setTimeout(() => {
						this.printLabelImage()
					});
	
				}
				else {
					this.popToast('Failed!', res.data.message, 'danger')
				}
				
				this.isLoaded = false
				this.lm_awb = ''
			} catch (err) {
				this.lm_awb = ''
				this.isLoaded = false
				console.error(err)
			}
		},

		//Check JSPM WebSocket status
		jspmWSStatus() {
			if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
				return true;
			}else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Closed) {
				alert('JSPrintManager (JSPM) is not installed or not running in the print server! Download and install JSPM Client App from https://neodynamic.com/downloads/jspm');
				this.isReady = false
				return false;
			}
			else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Blocked) {
				alert('JSPM has blocked this website!');
				return false;
			}
		},

		async printLabelImage() {

			if(this.jspmWSStatus()) {

				if(this.labelfiletype == 'pdf') {

					if(this.$store.getters.global.label_printer) {
						let cpj = new JSPM.ClientPrintJob();
						cpj.clientPrinter = new JSPM.InstalledPrinter(this.$store.getters.global.label_printer);

						//Set content to print... in this sample, a pdf file
						const myPdfFile = new JSPM.PrintFilePDF(this.label_url, JSPM.FileSourceType.URL, `${this.lm_awb}.pdf`, 1);

						//add file to print job
						cpj.files.push(myPdfFile);

						//Send print job to printer!
						cpj.sendToClient();
					}
				}
				else if(this.labelfiletype == 'dpdLabel') {

					if(this.$store.getters.global.label_printer) {

						let cpj = new JSPM.ClientPrintJob();
						cpj.clientPrinter = new JSPM.InstalledPrinter(this.$store.getters.global.label_printer);

						cpj.printerCommands = this.dpdLabel_url;

						//Send print job to printer!
						cpj.sendToClient();

					}

				}
				else {

					html2canvas(document.querySelector("#capture-label")).then(canvas => {
						// document.body.appendChild(canvas)
						if(this.$store.getters.global.label_printer) {
							let cpj = new JSPM.ClientPrintJob();
							cpj.clientPrinter = new JSPM.InstalledPrinter(this.$store.getters.global.label_printer);

							//Set content to print... 
							const b64Prefix = "data:image/png;base64,";
							const imgBase64DataUri = canvas.toDataURL("image/png");
							const imgBase64Content = imgBase64DataUri.substring(b64Prefix.length, imgBase64DataUri.length);
							const myImageFile = new JSPM.PrintFile(imgBase64Content, JSPM.FileSourceType.Base64, 'labelToPrint.png', 1);
							
							//add file to print job
							cpj.files.push(myImageFile);
							cpj.sendToClient();
						}
					});
				}
				this.$refs.lm_or_hawm.focus()
			}
		},

		async getSellerDoc() {
			try {
				if(!this.seller_doc_printer){
					this.popToast('Failed!', "Please set printer for Seller Doc!", 'danger')
					this.sellerDocAwb = null;
					return false;
				}
				this.ifSellerDocLoading = true;
				this.sellerDocUrl = null;
				let res = await this.axios.get(`/overseas-label-doc/${this.sellerDocAwb}`);

				if(res.data.success) {
					this.sellerDocUrl = res.data.result.url;
					this.sellerDocUrl.includes('.pdf') ? this.filetype = 'pdf':this.filetype = 'png'
					setTimeout(() => {
						this.printSellerDoc(this.sellerDocUrl, res.data.result.base64, this.sellerDocAwb)
					});
			
				} else {
					this.popToast('Failed!', res.data.message, 'danger')
				}
				this.ifSellerDocLoading = false;
				this.sellerDocAwb = ''

			} catch (error) {
				this.popToast('Failed!', error.response.data.message, 'danger')
				this.ifSellerDocLoading = false;
			}
		},
		
		async printSellerDoc(sellerDocUrl, base64, fileName) {
			if(this.jspmWSStatus()) {
				if(this.filetype == 'pdf') {
					if(this.$store.getters.global.seller_doc_printer) {
						let cpj = new JSPM.ClientPrintJob();
						cpj.clientPrinter = new JSPM.InstalledPrinter(this.$store.getters.global.seller_doc_printer);
						
						const blob = this.base64ToBlob( base64.split(",")[1] );
						const blobUrl = URL.createObjectURL( blob );
					
						//Set content to print... in this sample, a pdf file
						const myPdfFile = new JSPM.PrintFilePDF(blobUrl, JSPM.FileSourceType.URL, `${fileName}.pdf`, 1);
						myPdfFile.pageSizing = JSPM.Sizing.Fit;

						//add file to print job
						cpj.files.push(myPdfFile);
						//Send print job to printer!
						cpj.sendToClient();
					}

				} else {

					html2canvas(document.querySelector("#capture-seller-doc")).then(async () => {
						// document.body.appendChild(canvas)
						if(this.$store.getters.global.seller_doc_printer) {
							let cpj = new JSPM.ClientPrintJob();
							cpj.clientPrinter = new JSPM.InstalledPrinter(this.$store.getters.global.seller_doc_printer);
							

							//Set content to print... 
							const fileResult = await this.axios.post('/convert-url-to-file', {fileUrl: sellerDocUrl});
							
							if (fileResult.data.success) {
								const imgBase64Content = fileResult.data.data;
								const myImageFile = new JSPM.PrintFile(imgBase64Content, JSPM.FileSourceType.Base64, 'sellerDocToPrint.png', 1);
								//add file to print job
								cpj.files.push(myImageFile);
								cpj.sendToClient();
							}
						}
					});
				}

				this.sellerDocAwb = '';
				this.$refs.lm_or_hawm.focus()
			}
		},
		imageLoadError () {
			this.popToast('Failed!', `Image of AWB ${this.sellerDocAwb} failed to load due to broken link`, 'danger')
			this.sellerDocUrl = '';
		},
		base64ToBlob( base64, type = "application/octet-stream" ) {
            const binStr = atob( base64 );
            const len = binStr.length;
            const arr = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                arr[ i ] = binStr.charCodeAt( i );
			}
			return new Blob( [ arr ], { type: type } );
		}
	},
	computed: {
		label_printer() {
			return this.$store.getters.global.label_printer || null
		},
		seller_doc_printer() {
			return this.$store.getters.global.seller_doc_printer || null
		}
	},
	
}
</script>

<style lang="scss" scoped>
	img, .pdf {
		width: 100%;
	}
	.img {
		width: 100%;
		height: 450px;
		overflow: auto;
	}
</style>
